import type { FC, MutableRefObject } from 'react';
import classNames from 'classnames';
import Input from '../Input/Input';
import styles from './PasswordInput.module.scss';
import Button from '../Button/Button';
import useToggle from '../hooks/useToggle';

interface Props {
    className?: string;
    elementReference?: MutableRefObject<HTMLInputElement | null>;
    error?: boolean;
    errorMessage?: string;
    label: string;
    maxLength?: number;
    onBlur?: () => void;
    onChange: (password: string) => void;
    password: string;
    readOnly?: boolean;
    required?: boolean;
}

const PasswordInput: FC<Props> = ({
    className,
    elementReference,
    error,
    errorMessage,
    label,
    maxLength,
    onBlur,
    onChange,
    password,
    readOnly,
    required,
}) => {
    const [showPassword, toggleShowPassword] = useToggle(false);

    return (
        <div className={classNames(styles.wrapper, className)}>
            <Input
                type={showPassword ? 'text' : 'password'}
                value={password}
                label={label}
                maxLength={maxLength}
                onBlur={onBlur}
                readOnly={readOnly}
                required={required}
                onChange={onChange}
                elementReference={elementReference}
                error={error}
                errorMessage={errorMessage}
            />

            <Button
                className={styles.toggle}
                icon={showPassword ? 'fa-eye-slash' : 'fa-eye'}
                isClean
                iconOnly
                onClick={toggleShowPassword}
            />
        </div>
    );
};

export default PasswordInput;
