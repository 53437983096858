import type { MutationFunction } from '@tanstack/react-query';
import type { Potential } from '../../../../js/types';
import { isObject } from '../../../../js/typeguards';
import fetch from '../../../../js/api/fetch';

export interface LoginResponse {
    message: string;
    redirectPath: string | null;
}

export interface LoginQueryArgs {
    email: string;
    password: string;
    redirect?: string | null;
}

const isLoginResponse = (data: Potential<LoginResponse>): data is LoginResponse => (
    isObject(data)
    && typeof data.message === 'string'
    && (typeof data.redirectPath === 'string' || data.redirectPath === null)
);

const attemptLogin = async ({ email, password, redirect }: LoginQueryArgs): Promise<LoginResponse> => {
    const response = await fetch('/auth/login', {
        body: JSON.stringify({ email, password, redirect }),
        method: 'POST',
    });

    const responseData = await response.json() as Potential<LoginResponse>;

    if (isLoginResponse(responseData)) {
        return responseData;
    }

    throw new Error('Unexpected data returned');
};

const loginMutation = (): { mutationFn: MutationFunction<LoginResponse, LoginQueryArgs> } => ({
    mutationFn: (args: LoginQueryArgs) => attemptLogin(args),
});

export default loginMutation;
