import type { MutationOptions } from '@tanstack/react-query';
import type { Potential } from '../../../../js/types';
import { isObject } from '../../../../js/typeguards';
import fetch from '../../../../js/api/fetch';
import type { FetchResponseNotOkError } from '../../../../js/api/FetchResponseNotOkError';

export interface UserLookupResponse {
    confirmationPending: boolean;
    email: string;
    exists: boolean;
}

const isUserLookupResponse = (data: Potential<UserLookupResponse>): data is UserLookupResponse => (
    isObject(data)
    && typeof data.exists === 'boolean'
    && typeof data.confirmationPending === 'boolean'
    && typeof data.email === 'string'
);

const lookupUser = async (email: string): Promise<UserLookupResponse> => {
    const response = await fetch('/auth/lookup', {
        body: JSON.stringify({ email }),
        method: 'POST',
    });

    const responseData = await response.json() as Potential<UserLookupResponse>;

    if (isUserLookupResponse(responseData)) {
        return responseData;
    }

    throw new Error('Unexpected data returned');
};

const lookupUserMutation = (): MutationOptions<UserLookupResponse, FetchResponseNotOkError, string> => ({
    mutationFn: (email: string) => lookupUser(email),
});

export default lookupUserMutation;
