import type { FC, PropsWithChildren } from 'react';
import { useQuery } from '@tanstack/react-query';
import authFlowStateQuery from '../../Query/AuthFlowStateQuery';
import TranslationProvider from '../../../../general/Translation/TranslationProvider';
import type { ProviderProps } from './AuthFlowStateContext';
import { AuthFlowStateContext } from './AuthFlowStateContext';

/**
 * We do not have any preloaded state when we render the authentication flow in the drawer, so we'll have to retrieve that asynchronously
 */
const AsyncAuthFlowStateProvider: FC<PropsWithChildren<ProviderProps>> = ({
    authFlowEnvironment,
    children,
    loader,
    loginRedirectPath,
}) => {
    const { data: authFlowState } = useQuery(authFlowStateQuery());

    if (!authFlowState) {
        return loader;
    }

    return (
        <AuthFlowStateContext.Provider value={{ ...authFlowState, authFlowEnvironment, loginRedirectPath }}>
            <TranslationProvider dictionary={authFlowState.translations}>
                {children}
            </TranslationProvider>
        </AuthFlowStateContext.Provider>
    );
};

export default AsyncAuthFlowStateProvider;
