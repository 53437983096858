import type { MutationFunction } from '@tanstack/react-query';
import type { Potential } from '../../../../js/types';
import { isObject } from '../../../../js/typeguards';
import fetch from '../../../../js/api/fetch';

export interface RegisterResponse {
    message: string;
}

export interface RegisterMutationArgs extends Record<string, string> {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
}

const isRegisterResponse = (data: Potential<RegisterResponse>): data is RegisterResponse => (
    isObject(data)
    && typeof data.message === 'string'
);

const registerAccount = async ({ email, firstName, lastName, password }: RegisterMutationArgs): Promise<RegisterResponse> => {
    const response = await fetch('/auth/register', {
        body: JSON.stringify({ email, firstName, lastName, password }),
        method: 'POST',
    });

    const responseData = await response.json() as Potential<RegisterResponse>;

    if (isRegisterResponse(responseData)) {
        return responseData;
    }

    throw new Error('Unexpected data returned');
};

const registerMutation = (): { mutationFn: MutationFunction<RegisterResponse, RegisterMutationArgs> } => ({
    mutationFn: (args: RegisterMutationArgs) => registerAccount(args),
});

export default registerMutation;
