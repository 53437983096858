import type { FC } from 'react';
import { useContext } from 'react';
import { AuthFlowStateContext } from '../../../Provider/AuthFlowStateProvider/AuthFlowStateContext';
import { window } from '../../../../../../js/globals';

const NotFound: FC = () => {
    const { urls } = useContext(AuthFlowStateContext);

    if (window) {
        window.location.href = urls.notFound;
    }

    return null;
};

export default NotFound;
