import type { MutationFunction } from '@tanstack/react-query';
import type { Potential } from '../../../../js/types';
import { isObject } from '../../../../js/typeguards';
import fetch from '../../../../js/api/fetch';

export interface PasswordResetRequestResponse {
    message: string;
}

const isPasswordResetResponse = (data: Potential<PasswordResetRequestResponse>): data is PasswordResetRequestResponse => (
    isObject(data)
    && typeof data.message === 'string'
);

const requestPasswordReset = async (email: string): Promise<PasswordResetRequestResponse> => {
    const response = await fetch('/auth/request-password-reset', {
        body: JSON.stringify({ email }),
        method: 'POST',
    });

    const responseData = await response.json() as Potential<PasswordResetRequestResponse>;

    if (isPasswordResetResponse(responseData)) {
        return responseData;
    }

    throw new Error('Unexpected data returned');
};

const passwordResetRequestMutation = (): { mutationFn: MutationFunction<PasswordResetRequestResponse, string> } => ({
    mutationFn: (email: string) => requestPasswordReset(email),
});

export default passwordResetRequestMutation;
