import type { FC } from 'react';
import classNames from 'classnames';
import Icon from '../../../../../stories/Atoms/Icon/Icon';
import styles from './Message.module.scss';

export enum MessageType {
    Error = 'error',
    Success = 'success',
}

interface Props {
    className?: string;
    message: string;
    type: MessageType;
}

const Message: FC<Props> = ({ className, message, type }) => {
    const icon = type === MessageType.Error ? 'fa-circle-exclamation' : 'fa-check';

    return (
        <div data-testid="message" className={classNames(styles.message, className, {
            [styles.error as string]: type === MessageType.Error,
            [styles.success as string]: type === MessageType.Success,
        })}>
            <div><Icon name={icon}/></div>
            <span className="ml-2">{message}</span>
        </div>
    );
};

export default Message;
