import type { FC, FormEvent, MouseEvent } from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RegistrationContext } from '../../../../Provider/RegistrationProvider/RegistrationProvider';
import Input from '../../../../../../general/Input/Input';
import Button from '../../../../../../general/Button/Button';
import ButtonStack from '../../../../../../general/Button/ButtonStack';
import { PublicRoute } from '../../../../Navigation/AuthenticationRoutes';
import useTranslate from '../../../../../../general/Translation/hooks/UseTranslate';
import { AuthFlowEnvironment } from '../../../../AuthFlowEnvironment';
import BottomSection from '../../BottomSection/BottomSection';
import { AuthFlowStateContext } from '../../../../Provider/AuthFlowStateProvider/AuthFlowStateContext';

const UserIdentityForm: FC = () => {
    const { authFlowEnvironment } = useContext(AuthFlowStateContext);
    const { firstName, lastName, setFirstName, setLastName } = useContext(RegistrationContext);
    const navigate = useNavigate();
    const translate = useTranslate();

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (!firstName) {
            return;
        }

        navigate(PublicRoute.REGISTER_STEP_3);
    };

    const onLoginRouteRequest = (e: MouseEvent) => {
        e.preventDefault();
        navigate(PublicRoute.WELCOME);
    };

    return (
        <form onSubmit={onSubmit}>
            <div>
                <h2 className="mb-6">{translate('auth', 'your_name_title')}</h2>
                <p className="mb-8">{translate('auth', 'call_you_by_name_subtitle')}</p>

                <Input
                    label={translate('auth', 'first_name_label')}
                    type="text"
                    value={firstName}
                    onChange={setFirstName}
                    required
                />

                <Input
                    label={translate('auth', 'last_name_label')}
                    type="text"
                    value={lastName}
                    onChange={setLastName}
                />

                <ButtonStack isFluid className="mt-4">
                    <Button type="submit" isPrimary disabled={!firstName}>
                        {translate('auth', 'continue_button')}
                    </Button>
                </ButtonStack>

                <BottomSection className="mt-10" borderTop={authFlowEnvironment === AuthFlowEnvironment.DRAWER}>
                    {translate('auth', 'already_have_an_account')}
                    {' '}
                    <a onClick={onLoginRouteRequest}>{translate('auth', 'to_login_button')}</a>
                </BottomSection>
            </div>
        </form>
    );
};

export default UserIdentityForm;
