import type { ChangeEvent, FC, HTMLInputTypeAttribute, MutableRefObject } from 'react';
import { useId } from 'react';
import classNames from 'classnames';
import Icon from '../../../stories/Atoms/Icon/Icon';

interface Props {
    autoComplete?: string;
    className?: string;
    elementReference?: MutableRefObject<HTMLInputElement | null>;
    error?: boolean;
    errorMessage?: string;
    label: string;
    maxLength?: number;
    onBlur?: () => void;
    onChange?: (value: string) => void;
    readOnly?: boolean;
    required?: boolean;
    type: HTMLInputTypeAttribute;
    value: string;
}

const Input: FC<Props> = ({
    autoComplete,
    className,
    elementReference,
    error,
    errorMessage,
    label,
    maxLength,
    onBlur,
    onChange,
    readOnly,
    required,
    type,
    value,
}) => {
    const id = useId();
    const onChangeCallback = (e: ChangeEvent<HTMLInputElement>) => onChange?.(e.target.value);

    return (
        <div className={classNames('form-row', className)}>
            <div className={classNames('form-control', { 'has-warning': error })}>
                <input
                    id={`input-${id}`}
                    ref={elementReference}
                    className={classNames('form-input', { dirty: Boolean(value) })}
                    type={type}
                    maxLength={maxLength}
                    autoComplete={autoComplete}
                    onChange={onChangeCallback}
                    onBlur={onBlur}
                    value={value}
                    readOnly={readOnly}
                    required={required}
                />
                <label className="form-label" htmlFor={`input-${id}`}>{label}</label>
            </div>

            {errorMessage && (
                <ul className="form-error">
                    <li className="error">
                        <Icon name="fa-circle-exclamation" />{errorMessage}
                    </li>
                </ul>
            )}
        </div>
    );
};

export default Input;
