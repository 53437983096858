import type { CSSProperties, FC } from 'react';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './PasswordStrengthIndicator.module.scss';
import useTranslate from '../../../../general/Translation/hooks/UseTranslate';
import { PasswordStrength, PasswordStrengthResult } from './PasswordStrength';

interface Props {
    className?: string;
    password: string;
}

const PasswordStrengthIndicator: FC<Props> = ({ className, password }) => {
    const translate = useTranslate();

    const [
        passwordStrengthResult,
        setPasswordStrengthResult,
    ] = useState<PasswordStrengthResult>(new PasswordStrengthResult(password));

    useEffect(() => setPasswordStrengthResult(new PasswordStrengthResult(password)), [password]);

    const { numericPasswordStrength, passwordStrength } = passwordStrengthResult;

    const progressStyle = { '--value': `${numericPasswordStrength}%` } as CSSProperties;

    return (
        <div className={className}>
            <div
                data-testid="password-strength-indicator"
                className={classNames(
                    styles.progress,
                    {
                        [styles['very-weak'] as string]: passwordStrength === PasswordStrength.TOO_SHORT,
                        [styles.weak as string]: passwordStrength === PasswordStrength.WEAK,
                        [styles.good as string]: passwordStrength === PasswordStrength.GOOD,
                        [styles.strong as string]: passwordStrength === PasswordStrength.STRONG,
                    },
                )}
                style={progressStyle}
            />

            <div className={styles.description}>
                <div>{translate('auth', 'password_strength')}</div>
                <div>{translate('auth', passwordStrength)}</div>
            </div>
        </div>
    );
};

export default PasswordStrengthIndicator;
