import type { FC } from 'react';
import classNames from 'classnames';
import Button from '../../../../../general/Button/Button';
import ButtonStack from '../../../../../general/Button/ButtonStack';
import styles from './GoogleLogin.module.scss';
import Translation from '../../../../../general/Translation/Translation';
import { window } from '../../../../../../js/globals';
import { invalidatePersistedAuthorizedUserData } from '../../../Provider/AuthenticationProvider/PersistedAuthorizedUserData';

interface Props {
    loginRedirectPath?: string | null;
    url: string;
}

const createGoogleLoginUrl = (urlString: string, loginRedirectPath?: string | null) => {
    try {
        const url = new URL(urlString);

        if (loginRedirectPath) {
            url.searchParams.set('redirect', loginRedirectPath);
        }

        return url.toString();
    } catch (error) {
        return urlString;
    }
};

const GoogleLogin: FC<Props> = ({ loginRedirectPath, url }) => {
    /**
     * To save ourselves from current and possibly future SSR html mismatches,
     * we'll just redirect the user with javascript rather than a link, which can differ on the client or server,
     * e.g. due to a redirect path changing after a user clicks on a certain link.
     */
    const onGoogleLoginRequest = () => {
        if (window) {
            invalidatePersistedAuthorizedUserData();
            window.location.href = createGoogleLoginUrl(url, loginRedirectPath);
        }
    };

    return (
        <ButtonStack isFluid>
            <Button isGhost className={classNames('button--has-icon', styles.google)} onClick={onGoogleLoginRequest}>
                <div>
                    <Translation pageString="auth" stringId="google_sign_in_button"/>
                    <img src="/assets/images/template/google-signin-logo.png" alt="Google"/>
                </div>
            </Button>
        </ButtonStack>
    );
};

export default GoogleLogin;
