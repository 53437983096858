import type { UseMutateFunction } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import type { FetchResponseNotOkError } from '../../../../js/api/FetchResponseNotOkError';
import type { LoginQueryArgs, LoginResponse } from '../Query/LoginMutation';
import loginMutation from '../Query/LoginMutation';
import { invalidatePersistedAuthorizedUserData } from '../Provider/AuthenticationProvider/PersistedAuthorizedUserData';
import useTranslate from '../../../general/Translation/hooks/UseTranslate';

interface UseLogin {
    errorMessage: string | undefined;
    isError: boolean;
    isIdle: boolean;
    isPending: boolean;
    isSuccess: boolean;
    login: UseMutateFunction<LoginResponse, FetchResponseNotOkError, LoginQueryArgs>;
}

interface Args {
    onSuccess?: (redirectPath: string | null) => void;
}

const useLogin = ({ onSuccess }: Args): UseLogin => {
    const translate = useTranslate();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const parseError = (error: FetchResponseNotOkError) => {
        error.response.json()
            .then((data: { message: string }) => setErrorMessage(data.message || translate('auth', 'generic_error_message')))
            .catch(() => setErrorMessage(translate('auth', 'generic_error_message')));
    };

    const { isError, isIdle, isPending, isSuccess, mutate: login } = useMutation<LoginResponse, FetchResponseNotOkError, LoginQueryArgs>({
        ...loginMutation(),
        onError: parseError,
        onSuccess: ({ redirectPath }) => {
            invalidatePersistedAuthorizedUserData();

            onSuccess?.(redirectPath);
        },
    });

    return {
        errorMessage,
        isError,
        isIdle,
        isPending,
        isSuccess,
        login,
    };
};

export default useLogin;
