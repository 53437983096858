import type { MutationFunction } from '@tanstack/react-query';
import type { Potential } from '../../../../js/types';
import { isObject } from '../../../../js/typeguards';
import fetch from '../../../../js/api/fetch';

export interface RequestEmailConfirmationResponse {
    message: string;
}

const isRequestEmailConfirmationResponse = (data: Potential<RequestEmailConfirmationResponse>): data is RequestEmailConfirmationResponse => (
    isObject(data)
    && typeof data.message === 'string'
);

const requestEmailConfirmation = async (email: string): Promise<RequestEmailConfirmationResponse> => {
    const response = await fetch('/auth/request-email-confirmation', {
        body: JSON.stringify({ email }),
        method: 'POST',
    });

    const responseData = await response.json() as Potential<RequestEmailConfirmationResponse>;

    if (isRequestEmailConfirmationResponse(responseData)) {
        return responseData;
    }

    throw new Error('Unexpected data returned');
};

const requestEmailConfirmationMutation = (): { mutationFn: MutationFunction<RequestEmailConfirmationResponse, string> } => ({
    mutationFn: (email: string) => requestEmailConfirmation(email),
});

export default requestEmailConfirmationMutation;
