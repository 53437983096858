import type { FC, PropsWithChildren } from 'react';
import { useState, createContext } from 'react';

export interface RegistrationContextData {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    setEmail: (email: string) => void;
    setFirstName: (firstName: string) => void;
    setLastName: (lastName: string) => void;
    setPassword: (password: string) => void;
}

export const RegistrationContext = createContext<RegistrationContextData>(null as unknown as RegistrationContextData);

const RegistrationProvider: FC<PropsWithChildren> = ({ children }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    return (
        <RegistrationContext.Provider value={{
            email,
            firstName,
            lastName,
            password,
            setEmail,
            setFirstName,
            setLastName,
            setPassword,
        }}>
            {children}
        </RegistrationContext.Provider>
    );
};

export default RegistrationProvider;
