import type { Location } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const useRouteState = <State>(): State => {
    const location = useLocation() as Location<State>;

    return location.state || {} as State;
};

export default useRouteState;
