export enum PasswordStrength {
    GOOD = 'good',
    STRONG = 'strong',
    TOO_SHORT = 'too_short',
    WEAK = 'weak',
}

export class PasswordStrengthResult {
    public readonly passwordStrength: PasswordStrength = PasswordStrength.TOO_SHORT;

    public readonly numericPasswordStrength: number = 0;

    constructor(password: string) {
        this.passwordStrength = PasswordStrengthResult.calculatePasswordStrength(password);
        this.numericPasswordStrength = PasswordStrengthResult.calculateNumericPasswordStrength(password);
    }

    private static calculatePasswordStrength(password: string): PasswordStrength {
        if (password.length < 8) {
            return PasswordStrength.TOO_SHORT;
        } if (password.length < 11) {
            return PasswordStrength.WEAK;
        } if (password.length < 14) {
            return PasswordStrength.GOOD;
        }
        return PasswordStrength.STRONG;
    }

    private static calculateNumericPasswordStrength(password: string): number {
        return Math.min((100 / 14) * password.length, 100);
    }
}
