import type { FC } from 'react';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import Button from '../../../../../general/Button/Button';
import ButtonStack from '../../../../../general/Button/ButtonStack';
import requestEmailConfirmationMutation from '../../../Query/RequestEmailConfirmationMutation';
import useTranslate from '../../../../../general/Translation/hooks/UseTranslate';
import type { FetchResponseNotOkError } from '../../../../../../js/api/FetchResponseNotOkError';
import Message, { MessageType } from '../../Message/Message';
import useRouteState from '../../../Hooks/UseRouteState';

const EmailConfirmation: FC = () => {
    const translate = useTranslate();
    const routeState = useRouteState<{ email: string } | undefined>();

    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();

    const parseError = (error: FetchResponseNotOkError) => {
        error.response.json()
            .then((data: { message: string }) => setErrorMessage(data.message || translate('auth', 'generic_error_message')))
            .catch(() => setErrorMessage(translate('auth', 'generic_error_message')));
    };

    const { isError, isPending, isSuccess, mutate: requestEmailConfirmation } = useMutation({
        ...requestEmailConfirmationMutation(),
        onError: parseError,
        onSuccess: ({ message }) => setSuccessMessage(message),
    });

    const onEmailConfirmationRequest = () => {
        if (!routeState?.email) {
            return;
        }

        requestEmailConfirmation(routeState.email);
    };

    return (
        <div>
            <h2 className="mb-6">{translate('auth', 'almost_done_title')}</h2>

            <p className="mb-6">{translate('auth', 'email_verification_mail_sent_explanation')}</p>
            <p className="mb-10">{translate('auth', 'resend_email_verification_mail_explanation')}</p>

            {isError && errorMessage && <Message className="mb-4" message={errorMessage} type={MessageType.Error} />}
            {isSuccess && successMessage && <Message className="mb-4" message={successMessage} type={MessageType.Success} />}

            <ButtonStack isFluid>
                <Button type="submit" isPrimary isLoading={isPending} disabled={isPending} onClick={onEmailConfirmationRequest}>
                    {translate('auth', 'resend_email_button')}
                </Button>
            </ButtonStack>
        </div>
    );
};

export default EmailConfirmation;
