import type { FC } from 'react';
import { useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import Button from '../../../../../general/Button/Button';
import Input from '../../../../../general/Input/Input';
import ButtonStack from '../../../../../general/Button/ButtonStack';
import { UserLookupContext } from '../../../Provider/UserLookupProvider/UserLookupProvider';
import Message, { MessageType } from '../../Message/Message';
import useTranslate from '../../../../../general/Translation/hooks/UseTranslate';
import type { PasswordResetRequestResponse } from '../../../Query/PasswordResetRequestMutation';
import passwordResetRequestMutation from '../../../Query/PasswordResetRequestMutation';
import type { FetchResponseNotOkError } from '../../../../../../js/api/FetchResponseNotOkError';

const ForgotPassword: FC = () => {
    const translate = useTranslate();
    const { userLookup } = useContext(UserLookupContext);
    const [email, setEmail] = useState(userLookup?.email ?? '');

    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();

    const parseError = (error: FetchResponseNotOkError) => {
        error.response.json()
            .then((data: { message: string }) => setErrorMessage(data.message || translate('auth', 'generic_error_message')))
            .catch(() => setErrorMessage(translate('auth', 'generic_error_message')));
    };

    const { isError, isPending, isSuccess, mutate: requestPasswordReset } = useMutation<PasswordResetRequestResponse, FetchResponseNotOkError, string>({
        ...passwordResetRequestMutation(),
        onError: parseError,
        onSuccess: ({ message }) => setSuccessMessage(message),
    });

    return (
        <div>
            <h2 className="mb-6">{translate('auth', 'new_password_title')}</h2>
            <p className="mb-8">{translate('auth', 'password_reset_explanation')}</p>

            {isSuccess && <Message type={MessageType.Success} message={successMessage ?? ''}/>}
            {isError && errorMessage && <Message className="mb-4" message={errorMessage} type={MessageType.Error} />}

            <Input
                required
                label={translate('auth', 'email_label')}
                type="email"
                value={email}
                onChange={setEmail}
            />

            <ButtonStack isFluid className="mt-4">
                <Button
                    type="submit"
                    isPrimary
                    isLoading={isPending}
                    iconOnly={isSuccess}
                    icon={isSuccess ? 'fa-paper-plane' : undefined}
                    disabled={isPending || !email}
                    onClick={() => requestPasswordReset(email)}
                >
                    {!isSuccess && translate('auth', 'password_reset_button')}
                </Button>
            </ButtonStack>
        </div>
    );
};

export default ForgotPassword;
