import type { FC, PropsWithChildren } from 'react';
import TranslationProvider from '../../../../general/Translation/TranslationProvider';
import { AuthFlowStateContext, type ProviderProps } from './AuthFlowStateContext';
import AsyncAuthFlowStateProvider from './AsyncAuthFlowStateProvider';

const AuthFlowStateProvider: FC<PropsWithChildren<ProviderProps>> = ({
    authFlowEnvironment,
    children,
    loader,
    loginRedirectPath,
    preloadedState,
}) => {
    /**
     * We do not have any preloaded state when we render the authentication flow in the drawer, so we'll have to retrieve that asynchronously
     */
    if (!preloadedState) {
        return (
            <AsyncAuthFlowStateProvider authFlowEnvironment={authFlowEnvironment} loginRedirectPath={loginRedirectPath} loader={loader}>
                {children}
            </AsyncAuthFlowStateProvider>
        );
    }

    return (
        <AuthFlowStateContext.Provider value={{
            ...preloadedState,
            authFlowEnvironment,
            loginRedirectPath: loginRedirectPath ?? preloadedState?.loginRedirectPath,
        }}>
            <TranslationProvider dictionary={preloadedState.translations}>
                {children}
            </TranslationProvider>
        </AuthFlowStateContext.Provider>
    );
};

export default AuthFlowStateProvider;
