import type { FC } from 'react';
import { useCallback } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import type { UserLookupResponse } from '../../Query/LookupUserMutation';
import UserLookupProvider from '../../Provider/UserLookupProvider/UserLookupProvider';
import { PublicRoute } from '../../Navigation/AuthenticationRoutes';
import Welcome from './Welcome/Welcome';
import EmailLogin from './EmailLogin/EmailLogin';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ResetPassword from './ResetPassword/ResetPassword';
import EmailConfirmation from './EmailConfirmation/EmailConfirmation';
import CheckEmailAvailabilityForm from './Register/CheckEmailAvailabilityForm/CheckEmailAvailabilityForm';
import RegistrationProvider from '../../Provider/RegistrationProvider/RegistrationProvider';
import UserIdentityForm from './Register/UserIdentityForm/UserIdentityForm';
import UserCredentialsForm from './Register/UserCredentialsForm/UserCredentialsForm';
import NotFound from './NotFound/NotFound';

interface Props {
    layoutComponent?: JSX.Element;
}

const AuthenticationFlow: FC<Props> = ({ layoutComponent }) => {
    const navigate = useNavigate();

    const onUserLookup = useCallback((userLookup: UserLookupResponse) => {
        if (!userLookup.exists) {
            return;
        }

        if (userLookup.confirmationPending) {
            navigate(PublicRoute.EMAIL_CONFIRMATION_PENDING, { state: { email: userLookup.email } });
        } else {
            navigate(PublicRoute.EMAIL_LOGIN);
        }
    }, [navigate]);

    return (
        <UserLookupProvider onUserLookup={onUserLookup}>
            <RegistrationProvider>
                <Routes>
                    <Route element={layoutComponent}>
                        <Route path={PublicRoute.WELCOME} index element={<Welcome />} />
                        <Route path={PublicRoute.EMAIL_LOGIN} element={<EmailLogin />} />
                        <Route path={PublicRoute.FORGOT_PASSWORD} element={<ForgotPassword />} />
                        <Route path={PublicRoute.RESET_PASSWORD} element={<ResetPassword />} />
                        <Route path={PublicRoute.EMAIL_CONFIRMATION_PENDING} element={<EmailConfirmation />} />

                        <Route path={PublicRoute.REGISTER}>
                            <Route index element={<CheckEmailAvailabilityForm />} />
                            <Route path={PublicRoute.REGISTER_STEP_2} element={<UserIdentityForm />} />
                            <Route path={PublicRoute.REGISTER_STEP_3} element={<UserCredentialsForm />} />
                        </Route>

                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </RegistrationProvider>
        </UserLookupProvider>
    );
};

export default AuthenticationFlow;
