import type { FC, FormEvent, MouseEvent } from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../../general/Button/Button';
import Input from '../../../../../general/Input/Input';
import useLogin from '../../../Hooks/UseLogin';
import ButtonStack from '../../../../../general/Button/ButtonStack';
import { UserLookupContext } from '../../../Provider/UserLookupProvider/UserLookupProvider';
import PasswordInput from '../../../../../general/PasswordInput/PasswordInput';
import styles from './EmailLogin.module.scss';
import Message, { MessageType } from '../../Message/Message';
import { window } from '../../../../../../js/globals';
import { PublicRoute } from '../../../Navigation/AuthenticationRoutes';
import useRouteState from '../../../Hooks/UseRouteState';
import useTranslate from '../../../../../general/Translation/hooks/UseTranslate';
import { useWithSiteUrl } from '../../../../../general/WebshopContext/WebshopContext';
import { AuthFlowStateContext } from '../../../Provider/AuthFlowStateProvider/AuthFlowStateContext';

interface RouteState {
    message?: {
        content: string;
        type: MessageType;
    };
}

const EmailLogin: FC = () => {
    const withSiteUrl = useWithSiteUrl();
    const { userLookup } = useContext(UserLookupContext);
    const { loginRedirectPath } = useContext(AuthFlowStateContext);
    const navigate = useNavigate();
    const translate = useTranslate();
    const routeState = useRouteState<RouteState | undefined>();

    const [email, setEmail] = useState(userLookup?.email ?? '');
    const [password, setPassword] = useState('');

    const passwordRef = useRef<HTMLInputElement | null>(null);

    // If the user lookup has an email, we want to focus the password field
    useEffect(() => {
        if (userLookup?.email) {
            passwordRef.current?.focus();
        }
    }, [userLookup]);

    const {
        errorMessage,
        isError,
        isIdle: noLoginAttempts,
        isPending: isLoggingIn,
        isSuccess: isLoginSuccessful,
        login,
    } = useLogin({
        onSuccess: (redirectPath) => {
            if (window) {
                // Always reload the page, so any login status changes are reflected on the page
                if (redirectPath) {
                    window.location.href = withSiteUrl(redirectPath);
                } else {
                    window.location.reload();
                }
            }
        },
    });

    const attemptLogin = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        login({ email, password, redirect: loginRedirectPath });
    };

    const onPasswordForgotClick = (event: MouseEvent) => {
        event.preventDefault();

        navigate(PublicRoute.FORGOT_PASSWORD);
    };

    return (
        <div>
            <form onSubmit={attemptLogin}>
                <h2 className="mb-6">{translate('auth', 'welcome_back_title')}</h2>

                {isError && <Message className="mb-4" type={MessageType.Error} message={errorMessage ?? ''} />}

                {noLoginAttempts && routeState?.message && <Message className="mb-4" type={routeState.message.type} message={routeState.message.content} />}

                <Input
                    required
                    label={translate('auth', 'email_label')}
                    type="email"
                    value={email}
                    onChange={setEmail}
                />

                <PasswordInput
                    required
                    label={translate('auth', 'password_label')}
                    password={password}
                    onChange={setPassword}
                    elementReference={passwordRef}
                    className="mt-4"
                />

                <div className={styles['forgot-password']}>
                    <a onClick={onPasswordForgotClick}>
                        {translate('auth', 'forgot_password_button')}
                    </a>
                </div>

                <ButtonStack isFluid className="mt-6">
                    <Button
                        type="submit"
                        isPrimary
                        isLoading={isLoggingIn || isLoginSuccessful}
                        disabled={isLoggingIn || !password || !email || isLoginSuccessful}
                    >
                        {translate('auth', 'log_in_button')}
                    </Button>
                </ButtonStack>
            </form>
        </div>
    );
};

export default EmailLogin;
