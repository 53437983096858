import type { FC, PropsWithChildren } from 'react';
import { useState, createContext } from 'react';
import type { UseMutateFunction } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { UserLookupResponse } from '../../Query/LookupUserMutation';
import lookupUserMutation from '../../Query/LookupUserMutation';
import type { FetchResponseNotOkError } from '../../../../../js/api/FetchResponseNotOkError';
import useTranslate from '../../../../general/Translation/hooks/UseTranslate';

interface Props {
    onUserLookup: (userLookup: UserLookupResponse) => void;
}

interface UserLookupContextData {
    errorMessage: string | undefined;
    isError: boolean;
    isPending: boolean;
    lookupUser: UseMutateFunction<UserLookupResponse, FetchResponseNotOkError, string>;
    setUserLookup: (userLookup: UserLookupResponse) => void;
    userLookup: UserLookupResponse | undefined;
}

export const UserLookupContext = createContext<UserLookupContextData>(null as unknown as UserLookupContextData);

const UserLookupProvider: FC<PropsWithChildren<Props>> = ({ children, onUserLookup }) => {
    const translate = useTranslate();
    const [userLookup, setUserLookup] = useState<UserLookupResponse>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const parseError = (error: FetchResponseNotOkError) => {
        error.response.json()
            .then((data: { message: string }) => setErrorMessage(data.message || translate('auth', 'generic_error_message')))
            .catch(() => setErrorMessage(translate('auth', 'generic_error_message')));
    };

    const {
        isError,
        isPending,
        mutate: lookupUser,
    } = useMutation<UserLookupResponse, FetchResponseNotOkError, string>({
        ...lookupUserMutation(),
        onError: parseError,
        onSuccess: (user) => {
            setUserLookup(user);
            onUserLookup(user);
        },
    });
    return (
        <UserLookupContext.Provider value={{
            errorMessage,
            isError,
            isPending,
            lookupUser,
            setUserLookup,
            userLookup,
        }}>
            {children}
        </UserLookupContext.Provider>
    );
};

export type { UserLookupContextData };
export default UserLookupProvider;
