import type { QueryFunction, QueryKey } from '@tanstack/react-query';
import type { Potential } from '../../../../js/types';
import { isObject } from '../../../../js/typeguards';
import fetch from '../../../../js/api/fetch';
import type { MySurpriseAuthFlowState as AuthFlowStateResponse } from '../../AuthFlow/types';

const isAuthFlowStateResponse = (data: Potential<AuthFlowStateResponse>): data is AuthFlowStateResponse => (
    isObject(data)
    && typeof data.basePath === 'string'
    && typeof data.path === 'string'
    && isObject(data.siteMetaData)
    && isObject(data.translations)
    && isObject(data.urls)
);

const fetchAuthFlowState = async (signal?: AbortSignal): Promise<AuthFlowStateResponse> => {
    const response = await fetch('/mysurprise/auth-flow/state', { signal });

    const responseData = await response.json() as Potential<AuthFlowStateResponse>;

    if (isAuthFlowStateResponse(responseData)) {
        return responseData;
    }

    throw new Error('Unexpected data returned');
};

type AuthFlowStateQueryOptions = {
    gcTime: number;
    queryFn: QueryFunction<Omit<AuthFlowStateResponse, 'loginRedirectPath'>>;
    queryKey: QueryKey;
    staleTime: number;
};

const authFlowStateQuery = (): AuthFlowStateQueryOptions => ({
    gcTime: Infinity,
    queryFn: ({ signal }) => fetchAuthFlowState(signal),
    queryKey: ['mysurprise-auth-flow-state'],
    staleTime: Infinity,
});

export default authFlowStateQuery;
