import type { MutationOptions } from '@tanstack/react-query';
import type { Potential } from '../../../../js/types';
import { isObject } from '../../../../js/typeguards';
import fetch from '../../../../js/api/fetch';
import type { FetchResponseNotOkError } from '../../../../js/api/FetchResponseNotOkError';

export interface EmailAvailabilityResponse {
    available: boolean;
    email: string;
}

const isEmailAvailabilityResponse = (data: Potential<EmailAvailabilityResponse>): data is EmailAvailabilityResponse => (
    isObject(data)
    && typeof data.available === 'boolean'
    && typeof data.email === 'string'
);

const checkEmailAvailability = async (email: string): Promise<EmailAvailabilityResponse> => {
    const response = await fetch('/auth/check-email', {
        body: JSON.stringify({ email }),
        method: 'POST',
    });

    const responseData = await response.json() as Potential<EmailAvailabilityResponse>;

    if (isEmailAvailabilityResponse(responseData)) {
        return responseData;
    }

    throw new Error('Unexpected data returned');
};

const checkEmailAvailabilityMutation = (): MutationOptions<EmailAvailabilityResponse, FetchResponseNotOkError, string> => ({
    mutationFn: (email: string) => checkEmailAvailability(email),
});

export default checkEmailAvailabilityMutation;
