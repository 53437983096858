import type { MutationFunction } from '@tanstack/react-query';
import type { Potential } from '../../../../js/types';
import { isObject } from '../../../../js/typeguards';
import fetch from '../../../../js/api/fetch';

export interface PasswordResetResponse {
    message: string;
}

export interface PasswordResetMutationArgs extends Record<string, string> {
    password: string;
    token: string;
}

const isPasswordResetResponse = (data: Potential<PasswordResetResponse>): data is PasswordResetResponse => (
    isObject(data)
    && typeof data.message === 'string'
);

const resetPassword = async ({ password, token }: PasswordResetMutationArgs): Promise<PasswordResetResponse> => {
    const response = await fetch('/auth/password', {
        body: JSON.stringify({ password, token }),
        method: 'PUT',
    });

    const responseData = await response.json() as Potential<PasswordResetResponse>;

    if (isPasswordResetResponse(responseData)) {
        return responseData;
    }

    throw new Error('Unexpected data returned');
};

const passwordResetMutation = (): { mutationFn: MutationFunction<PasswordResetResponse, PasswordResetMutationArgs> } => ({
    mutationFn: (args: PasswordResetMutationArgs) => resetPassword(args),
});

export default passwordResetMutation;
