import type { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import styles from './BottomSection.module.scss';

interface Props {
    borderTop?: boolean;
    className?: string;
}

const BottomSection: FC<PropsWithChildren<Props>> = ({ borderTop, children, className }) => (
    <div className={classNames(styles.wrapper, className, { [styles['wrapper-with-border'] as string]: borderTop })}>
        {children}
    </div>
);

export default BottomSection;
